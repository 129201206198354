import ibanJS from 'iban';

const formatSortCode = (sortCode) => {
  if (!sortCode) return '';
  return (
    sortCode.replace(/[^0-9]+/g, '').match(/.{1,2}/g)?.join('-')
    || sortCode.replace(/[^0-9]+/g, '')
  );
};

const formatBic = (bic) => {
  if (!bic) return '';
  return bic.replace(/\s/g, '').toUpperCase();
};

const formatRoutingNo = (routingNo) => {
  if (!routingNo) return '';
  return routingNo.replace(/\s/g, '');
};

const isValidBic = (bic) => {
  if (!bic) return false;

  return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/
    .test(bic.toUpperCase());
};

const isValidSortCode = (value) => {
  if (!value) return false;

  const sortCode = value.replace(/[-\s]/g, '');

  return sortCode.length >= 5 && sortCode.length <= 6 && Number(sortCode);
};

const isValidAccountNo = (value, { length: [min, max] }) => {
  if (!value) return false;

  const accountNo = value.replace(/[-\s]/g, '');
  return accountNo.length >= min
    && accountNo.length <= max
    && Number(accountNo);
};

const isValidUSAccountNo = (value) => (
  isValidAccountNo(value, { length: [6, 17] })
);

const isValidCAAccountNo = (value) => (
  isValidAccountNo(value, { length: [7, 12] })
);

const isValidUKAccountNo = (value) => (
  isValidAccountNo(value, { length: [7, 8] })
);

const formatIban = (iban) => {
  if (!iban) return '';

  let ibanFormatted = ibanJS.electronicFormat(iban);
  let ibanLength;
  if (ibanFormatted?.length >= 2) {
    ibanLength = ibanJS.countries[ibanFormatted.slice(0, 2)]?.length || 0;
  }
  ibanFormatted = ibanFormatted.slice(0, ibanLength);
  return ibanJS.printFormat(ibanFormatted);
};

const isValidIban = ibanJS.isValid;

const ibanElectronicFormat = ibanJS.electronicFormat;

const bicElectronicFormat = formatBic;

const sortCodeElectronicFormat = (sortCode) => sortCode.replace(/[-\s]/g, '');

const accountNoElectronicFormat = (accountNo) => (
  accountNo.replace(/[-\s]/g, '')
);

const routingNoElectronicFormat = (routingNo) => formatRoutingNo(routingNo);

const isValidRoutingNo = (value) => {
  if (!value) return false;

  const routingNo = routingNoElectronicFormat(value);

  if (!(routingNo.length === 9 && Number(routingNo))) return false;

  const numbers = [...routingNo].map((number) => +number);

  const firstPart = 3 * (numbers[0] + numbers[3] + numbers[6]);
  const secondPart = 7 * (numbers[1] + numbers[4] + numbers[7]);
  const thirdPart = numbers[2] + numbers[5] + numbers[8];

  return ((firstPart + secondPart + thirdPart) % 10) === 0;
};

export {
  accountNoElectronicFormat,
  bicElectronicFormat,
  formatBic,
  formatIban,
  formatRoutingNo,
  formatSortCode,
  ibanElectronicFormat,
  isValidBic,
  isValidCAAccountNo,
  isValidIban,
  isValidRoutingNo,
  isValidSortCode,
  isValidUKAccountNo,
  isValidUSAccountNo,
  routingNoElectronicFormat,
  sortCodeElectronicFormat,
};
