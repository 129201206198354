import React from 'react';
import PropTypes from 'prop-types';
import { convertToHtml } from '@guuru/markdown-common';
import Markdown from './Markdown';

const MarkdownRender = function ({
  content,
  inline = false,
  style = undefined,
}) {
  const html = convertToHtml(content, inline);
  return inline
    ? (
      <div
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: html }}
        style={style}
      />
    )
    : (
      <Markdown
        style={style}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
};

MarkdownRender.propTypes = {
  content: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  style: PropTypes.shape({}),
};

export default MarkdownRender;
