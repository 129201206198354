import styled from 'styled-components';

const SmartFormText = styled.div`
  border: ${({ theme }) => `1px solid ${theme.disabledBorderColor}`};
  margin: 5px 12px 5px 0;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  min-width: inherit;
  background-color: ${({ theme }) => theme.disabledBackgroundColor};
  color: ${({ theme }) => theme.disabledTextColor};
`;

const Paragraph = styled.div`
  &:not(:last-child) {
    padding-bottom: 0.5rem;
  }

  overflow-wrap: anywhere;
`;

// eslint-disable-next-line import/prefer-default-export
export { SmartFormText, Paragraph };
