const handleFormErrors = function (error, form, {
  showError = console.warn,
  onInvalidFields,
  onFinish,
} = {}) {
  if (!error.graphQLErrors) {
    return showError(error.message);
  }

  const [{ message = error.message, extensions } = {}] = error.graphQLErrors;
  const fieldNotExists = extensions.invalidArgs?.some(({ key }) => (
    !Object.keys(form.getFieldsValue(true)).includes(key)
  ));
  if (!extensions.invalidArgs?.length || fieldNotExists) {
    return showError(message);
  } if (extensions.invalidArgs?.length > 0 && onInvalidFields) {
    onInvalidFields(extensions.invalidArgs);
  }

  const oldErrorsFields = form.getFieldsError()
    .filter((field) => field.errors.length)
    .map((field) => ({ errors: [], name: field.name }));

  const newErrorsFields = extensions.invalidArgs.map((field) => ({
    errors: [field.message],
    name: field.key,
  }));

  form.setFields(oldErrorsFields.concat(newErrorsFields));

  return onFinish ? onFinish() : null;
};

export default handleFormErrors;
