import React from 'react';
import { Spin } from 'antd';
import InfiniteScroller from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import InfiniteScrollContainer from './InfiniteScrollContainer';
import LoadingContainer from './LoadingContainer';

const InfiniteScroll = function ({
  disabled = false,
  hasMore = false,
  loading,
  loadMore,
  children,
}) {
  if (disabled) return children;
  return (
    <InfiniteScrollContainer>
      <InfiniteScroller
        initialLoad={false}
        pageStart={0}
        loadMore={loadMore}
        hasMore={!loading && hasMore}
        useWindow
        threshold={100}
      >
        {loading && hasMore && (
          <LoadingContainer>
            <Spin />
          </LoadingContainer>
        )}
        {children}
      </InfiniteScroller>
    </InfiniteScrollContainer>
  );
};

InfiniteScroll.propTypes = {
  loadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  hasMore: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InfiniteScroll;
