import { ChatAPI } from '@guuru/api-web';
import events from '@guuru/events-web';
import { put } from 'redux-saga/effects';
import { types } from '@guuru/chat-web';
import { messageTypes } from '@guuru/constants-common';

const { MESSAGE_TYPE_WELCOME } = messageTypes;

const WELCOME = 'bot:::welcome';

const createIntroWelcome = (message) => ChatAPI.messages.createBotMessage(
  new Date().getTime(),
  message,
  MESSAGE_TYPE_WELCOME,
  {},
  true,
  false,
);

export default {
  validate() {
    return true;
  },

  * render(partnerId, { welcomeMessage }) {
    events.welcome();
    const message = welcomeMessage || createIntroWelcome(WELCOME);
    ChatAPI.pushPendingMessages(message);
    yield put({ type: types.CHAT_NEW_TEXT_SUCCESS, message });
    return { goToNextStep: true };
  },

  processResponse() {},

};
